import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Text } from 'grommet';

import SmartLink from './SmartLink';
import { setActiveModal } from '../state/ui/uiSlice';
import { getProductPath } from '../lib/product';
import useVisibilitySettings from './useVisibilitySettings';

const BackdropMenuItem = ({
  blok: {
    text,
    link,
    product,
    textSize = 'medium',
    visibility,
    openInModal,
    modalPosition,
    modalFill,
  },
}) => {
  const dispatch = useDispatch();
  const url =
    product && product.item
      ? getProductPath(product.item)
      : link.linktype === 'email'
      ? `mailto:${link.email}`
      : link.linktype === 'story'
      ? `/${link.cached_url}`
      : link.cached_url;

  const isVisible = useVisibilitySettings(visibility);

  return isVisible ? (
    <Text
      size={textSize}
      style={{ letterSpacing: '0.05em', lineHeight: '23px' }}
    >
      <SmartLink
        to={url}
        plain={true}
        onClick={
          openInModal
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  setActiveModal({
                    fill: modalFill,
                    position: modalPosition,
                    story: link.id,
                  })
                );
              }
            : undefined
        }
      >
        {text}
      </SmartLink>
    </Text>
  ) : null;
};

BackdropMenuItem.propTypes = {
  blok: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    product: PropTypes.object,
    textSize: PropTypes.string,
    visibility: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    openInModal: PropTypes.bool,
    modalPosition: PropTypes.string,
    modalFill: PropTypes.array,
  }),
};

export default memo(BackdropMenuItem);
